import { ANIMATION_TRANSITION } from "./constants";

export const moveUp = `-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px) scale(1.05);
            transform: translateY(-20px) scale(1.05);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px) scale(1.05);
            transform: translateY(-20px) scale(1.05);
  }
}
}`;

export const moveDown = `-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-20px) scale(1.05);
            transform: translateY(-20px) scale(1.05);
  }
  100% {
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-20px) scale(1.05);
            transform: translateY(-20px) scale(1.05);
  }
  100% {
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
  }
}
}`;

export const bounceTopAnimation = `-webkit-animation: bounce-top 2.5s ease-in-out 2s;
animation: bounce-top 2.5s ease-in-out 2s;
animation-iteration-count: infinite;

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}`;

export const shakeAnimation = `-webkit-animation: shake-top 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
animation: shake-top 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
@-webkit-keyframes shake-top {
0%,
100% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
10% {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}
20%,
40%,
60% {
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
}
30%,
50%,
70% {
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
}
80% {
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
}
90% {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}
}
@keyframes shake-top {
0%,
100% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
10% {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}
20%,
40%,
60% {
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
}
30%,
50%,
70% {
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
}
80% {
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
}
90% {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}
}`;

export const fadeIn = `animation: fade-in ${ANIMATION_TRANSITION}s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`;
