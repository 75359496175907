import React from "react";
import styled from "styled-components";

import { Maybe, SanityFeaturedWorks, SanityWork } from "@graphql-types";
import { Container, P, BlueBackdrop, GridContainer, H2 } from "@util/standard";
import { Button } from "@sub";
import { MOBILE_BREAKPOINT, colors, fontSizes } from "@util/constants";
import WorkPreview from "@components/shared/workPreview";

interface Props {
  works?: Maybe<Maybe<SanityWork>[]> | undefined;
  data?: Maybe<SanityFeaturedWorks> | undefined;
  title?: string | undefined | null;
  description?: string | undefined | null;
}

const FeaturedWork = ({ data, title, description, works }: Props) => {
  const workData = works || data?.works;
  const workTitle = title || data?.workTitle;
  const workDescription = description || data?.workDescription;

  return (
    <Wrapper>
      <BlueBackdrop />
      <StyledContainer flexDirection="column" zIndex={1}>
        <TextContainer width="100%" flexDirection="column">
          <H2 fontSize={fontSizes.h1.default} color="white" margin="0">
            {workTitle}
          </H2>
          {workDescription && (
            <P margin="0 auto 30px 0" color="white">
              {workDescription}
            </P>
          )}
          <Button theme="whiteInverse" text="View more work" linkTo="/work/" />
        </TextContainer>
      </StyledContainer>
      <ContentContainer>
        <GridContainer repeat={3} rowGap="20px" columnGap="20px">
          {workData?.map((work) => {
            if (work == null) {
              return null;
            }

            return <WorkPreview smaller key={work._id} work={work} />;
          })}
        </GridContainer>
      </ContentContainer>
    </Wrapper>
  );
};
export default FeaturedWork;

const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 100px 0 100px 0;
  flex-direction: column;

  h2 {
    margin-bottom: 0px;
  }

  p {
    margin: 0 0 20px 0;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
    margin: 0;

    h2 {
      font-size: 34px;
      margin-bottom: 0px;
    }
  }
`;

const StyledContainer = styled(Container)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const TextContainer = styled(Container)`
  margin-bottom: 80px;
  max-width: 80%;
  margin-left: 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 auto 15px auto;
    background-color: ${colors.flightBlue};
    padding: 60px 40px 70px 40px;
    width: auto;
  }
`;
const ContentContainer = styled(Container)`
  flex-direction: column;
  z-index: 1;
  width: 90%;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;
