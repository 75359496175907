import {
  SanityBackgroundImageWithText,
  SanityCardCarousel,
  SanityColoredTextColumn,
  SanityColumn,
  SanityHubspotFormBlock,
  SanityImageBlock,
  SanityImageColumns,
  SanityImageTextColumn,
  SanityOtherServices,
  SanityRelatedWork,
  SanityScrollContainer,
  SanityTextAndCapabilities,
  SanityTextBlocksAndCapabilities,
  SanityTextCta,
  SanityVideoBlock
} from "@graphql-types";
import { animationType, buttonStyle, assets, colors, brandColors } from "@util/constants";

export type Dimensions = {
  width?: string;
  height?: string;
};

export type FlexDirection = "row" | "column" | "row-reverse" | "column-reverse" | "initial";
export type FlexWrap = "nowrap" | "wrap" | "wrap-reverse" | "initial";
export type JustifyContent =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-around"
  | "space-between"
  | "space-evenly"
  | "initial";
export type AlignItems = "flex-start" | "flex-end" | "center" | "stretch" | "initial";
export type AlignSelf = "auto" | "flex-start" | "flex-end" | "center" | "stretch" | "initial";
export type Overflow = "auto" | "scroll" | "visible" | "hidden" | "initial";

export type ButtonTheme = keyof typeof buttonStyle;
export type Assets = keyof typeof assets;
export type Color = keyof typeof colors;
export type BrandColors = keyof typeof brandColors;
export type AnimationType = typeof animationType;

export type PDisplayType = "inline" | "block" | "flex" | "initial";
export type Cursor = "grab" | "pointer" | "auto" | "initial";
export type WhiteSpace = "initial" | "normal" | "nowrap" | "pre-line" | "pre-wrap";

export type Display = "flex" | "inline" | "inline-block" | "grid" | "block" | "none";

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export interface Theme {
  text: string;
  background: string;
}

export function isSanityImageBlock(data: any): data is SanityImageBlock {
  return data._type === "imageBlock";
}

export function isSanityVideoBlock(data: any): data is SanityVideoBlock {
  return data._type === "videoBlock";
}

export function isSanityTextAndCapabilities(data: any): data is SanityTextAndCapabilities {
  return data._type === "textAndCapabilities";
}

export function isSanityImageTextColumn(data: any): data is SanityImageTextColumn {
  return data._type === "imageTextColumn";
}

export function isSanityImageColumns(data: any): data is SanityImageColumns {
  return data._type === "imageColumns";
}

export function isSanityScrollContainer(data: any): data is SanityScrollContainer {
  return data._type === "scrollContainer";
}

export function isSanityBackgroundImageWithText(data: any): data is SanityBackgroundImageWithText {
  return data._type === "backgroundImageWithText";
}

export function isSanityOtherServices(data: any): data is SanityOtherServices {
  return data._type === "otherServices";
}

export function isSanityRelatedWork(data: any): data is SanityRelatedWork {
  return data._type === "relatedWork";
}
export function isSanityColoredTextColumns(data: any): data is SanityColoredTextColumn {
  return data._type === "coloredTextColumn";
}

export function isSanityColumn(data: any): data is SanityColumn {
  return data._type === "column";
}

export function isSanityTextCta(data: any): data is SanityTextCta {
  return data._type === "textCta";
}

export function isSanityCardCarousel(data: any): data is SanityCardCarousel {
  return data._type === "cardCarousel";
}
export function isSanityHubspotFormBlock(data: any): data is SanityHubspotFormBlock {
  return data._type === "hubspotFormBlock";
}

export function isSanityTextBlocksAndCapabilities(
  data: any
): data is SanityTextBlocksAndCapabilities {
  return data._type === "textBlocksAndCapabilities";
}
