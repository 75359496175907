import React from "react";
import styled from "styled-components";

import { SanityImageColumns } from "@graphql-types";
import { Container } from "@util/standard";
import { Image } from "@components/shared/sub";
import { FluidObject } from "gatsby-image";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityImageColumns;
  width?: string;
}

const Wrapper = styled(Container)`
  margin: 0 auto 120px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 25px auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

function ImageColumns({ data, width }: Props) {
  if (data == null) return null;

  const { columnOne, columnTwo } = data;
  return (
    <Wrapper width={width ? width : "95%"} tabletWidth="100%" margin="0 auto 120px auto">
      {columnOne && columnOne.asset && columnOne.asset.fluid && (
        <Container width="50%" margin="0 2% 0 0" mobileWidth="100%" mobileMargin="0 0 25px 0">
          <Image fluid={columnOne.asset.fluid as FluidObject} />
        </Container>
      )}
      {columnTwo && columnTwo.asset && columnTwo.asset.fluid && (
        <Container width="50%" mobileWidth="100%" margin="0 0 0 2%" mobileMargin="0">
          <Image fluid={columnTwo.asset.fluid as FluidObject} />
        </Container>
      )}
    </Wrapper>
  );
}

export default ImageColumns;
