import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { SanityScrollContainer } from "@graphql-types";
import { animations, assets, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Image } from "@components/shared/sub";
import { FluidObject } from "gatsby-image";
import { useEventListener } from "@util/hooks";

interface Props {
  data: SanityScrollContainer;
}

const FrameWrapper = styled.div`
  background: url(${assets.laptopFrame});
  width: 56%;
  max-height: 461px;
  max-width: 825px;
  margin: 100px auto;
  overflow-y: scroll;
  overflow-x: visible;
  border: 11px solid white;
  border-radius: 10px;
  padding-right: 10px;
  background-color: white;
  position: relative;
  scrollbar-gutter: auto;
  ::-webkit-scrollbar {
    width: 5px;

    transform: translateX(100px);
  }

  ::-webkit-scrollbar-track {
    background: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: grey;
    opacity: 0.5;
    border-radius: 20px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

const ScrollWrapper = styled.div<{ rightOffset: number }>`
  position: absolute;
  top: 120px;
  ${({ rightOffset }) => rightOffset && `right: ${rightOffset - 65}px;`}
  z-index: 5;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ rightOffset }) => rightOffset && `left: ${rightOffset}px;`}
    top: 50px;
    right: unset;
  }
`;

const ScrollIndicator = styled.div`
  display: flex;

  p {
    margin-top: 10px;
  }

  transform: rotateZ(90deg);
  img {
    width: 30px;
    margin-left: 10px;
    ${animations.bounce("rotateZ(-90deg)")};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    transform: rotateZ(0deg);

    img {
      width: 20px;

      ${animations.bounce("rotateZ(-90deg)")};
    }
  }
`;

function ScrollContainer({ data }: Props) {
  if (data == null || data.image == null) return null;
  if (data.image.asset == null || data.image.asset.fluid == null) return null;
  const [rightOffset, setRightOffset] = useState<number>();
  const frameRef = useRef<HTMLDivElement>(null);

  const setScrollRightOffset = () => {
    if (frameRef.current) {
      setRightOffset(frameRef?.current?.offsetLeft);
    }
  };

  useEventListener("resize", setScrollRightOffset);

  useEffect(() => {
    setScrollRightOffset();
  }, []);

  return (
    <Container
      width="95%"
      tabletWidth="100%"
      margin="0 auto 120px auto"
      backgroundColor="olive"
      position="relative"
      tabletMargin="0 auto 25px auto"
    >
      {rightOffset && (
        <ScrollWrapper rightOffset={rightOffset ?? 0}>
          <ScrollIndicator>
            <p>Scroll</p>
            <img src={assets.scrollArrow} />
          </ScrollIndicator>
        </ScrollWrapper>
      )}
      <FrameWrapper ref={frameRef}>
        <Image fluid={data.image.asset.fluid as FluidObject} objectFit="cover" />
      </FrameWrapper>
    </Container>
  );
}

export default ScrollContainer;
